<template>
  <div class="softwareComponentList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="row ml-1">
        <template v-if="softwareComponents && installationId">
          <template v-for="(softwareComponent, index) in softwareComponents">
            <span
              :key="`software-component-${ index }`"
              :class="['float-left border software-component rounded p-2 mr-2 mb-1 mt-1', { 'active' : softwareComponent.id === selectedComponentId}]"
              style="cursor: pointer;"
              @click="setComponent(softwareComponent)"
            >
              <strong v-if="softwareComponent.name">{{ softwareComponent.name }} - {{ softwareComponent.id }}</strong>
              <strong v-else>{{ softwareComponent.type.name }} - {{ softwareComponent.id }}</strong>
            </span>
          </template>

          <div class="clearfix" />
        </template>
        <p v-else>
          {{ $t('noDataAvailable') }}
        </p>
        <button
          v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin'))"
          class="btn btn-sm btn-primary float-right mt-2 mb-2 mr-2 ml-auto"
          @click="startAutomataSync()"
        >
          <span>Automata Sync</span>
        </button>
        <button 
          v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin'))"
          class="btn btn-sm btn-primary float-right mt-2 mb-2 mr-2"
          @click="startEtlSync"
        >
          <span>
            ETL Sync
          </span>
        </button>
        <button 
          v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin'))"
          class="btn btn-sm btn-primary float-right mt-2 mb-2 mr-2"
          @click="startZabbixSync"
        >
          <span>
            Zabbix Sync
          </span>
        </button>
        <button 
          v-if="authenticationHasRole('secondlevel') || authenticationHasRole('admin')"
          class="btn btn-sm btn-primary float-right mt-2 mb-2 mr-2"
          @click="openSidebar"
        >
          <font-awesome-icon
            class="mr-1"
            icon="plus"
          />
          <span>
            {{ $t('add') }}
          </span>
        </button>
      </div>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <SoftwareComponentAdd
          :installation-id="installationId"
          @reload="reloadSoftwareComponents"
        />
      </Sidebar>
    </template>
  </div>
</template>
<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import SoftwareComponentAdd from '@/components/SoftwareComponent/SoftwareComponentAdd';
import Sidebar from '@/components/Base/Sidebar.vue';

export default {
  name: "SoftwareComponentList",
  components: {
    SoftwareComponentAdd,
    Sidebar
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    selectedId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      softwareComponents: null,
      showSidebar: false,
      selectedComponentId: null
    }
  },
  metaInfo () {
    return {
      title: this.$t('softwareComponent.softwareComponent')
    }
  },
  created () {
    this.getSoftwareComponents();
    if(!this.selectedId) {
      this.selectedComponentId = 0;
    }
    else {
      this.selectedComponentId = parseInt(this.selectedId);
    }
  },
  methods: {
    openSidebar () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    setComponent (component) {
      this.selectedComponentId = component.id;
      this.$emit("setSelectedComponent", component);
    },
    reloadSoftwareComponents () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getSoftwareComponents();
    },
    //api
    startZabbixSync () {
      this.axios.get(`/MonitoringSystem/StartZabbixSync?installationId=${ this.installationId }`)
      .then((response) => {
        if(response && response.status == 200) {
          this.$snotify.success("Zabbix Sync started.");
        }
        else {
          this.$snotify.success("Error while starting Zabbix Sync.");
        }
      })
    },
    startAutomataSync () {
      this.axios.get(`/SoftwareComponent/StartAutomataSync?installationId=${ this.installationId }`)
      .then((response) => {
        if(response && response.status == 200) {
          this.$snotify.success("Automata Sync started.");
        }
        else {
          this.$snotify.success("Error while starting Automata Sync.");
        }
      })
    },
    startEtlSync () {
      this.axios.get(`/SoftwareComponent/StartEtlSync?installationId=${ this.installationId }`)
      .then((response) => {
        if(response && response.status == 200) {
          this.$snotify.success("ETL Sync started.");
        }
        else {
          this.$snotify.success("Error while starting ETL Sync.");
        }
      })
    },
    getSoftwareComponents () {
      this.loading = true;
      this.$emit("reloading", true);
      this.axios.get(`/SoftwareComponent/GetSoftwareComponents?installationId=${ this.installationId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.softwareComponents = response.data;
        if(this.softwareComponents && this.softwareComponents.length > 0) {
          this.softwareComponents = this.softwareComponents.sort((a, b) => a.type.name.localeCompare(b.type.name));
          var selected = this.softwareComponents.find(x => x.id == this.selectedComponentId);
          if(selected) {
            this.$emit("setSelectedComponent", selected);
          }
          else {
            this.selectedComponentId = this.softwareComponents[0].id;
            this.$emit("setSelectedComponent", this.softwareComponents[0]);
          }
        }
      })
      .finally(() => {
        this.loading = false;
        this.$emit("reloading", false);
      });
    }     
  }
}
</script>

<style scoped>
.software-component {
  color: #3e3e4d;
}
.software-component.active {
  background: lightgray;
}
</style>