import { render, staticRenderFns } from "./SoftwareComponentAdd.vue?vue&type=template&id=b9a7dc44&scoped=true&"
import script from "./SoftwareComponentAdd.vue?vue&type=script&lang=js&"
export * from "./SoftwareComponentAdd.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./SoftwareComponentAdd.vue?vue&type=style&index=1&id=b9a7dc44&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9a7dc44",
  null
  
)

export default component.exports