var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"softwareComponentAdd"},[(_vm.loading)?_c('LoadingPlaceholder'):_c('form',[_c('div',{staticClass:"form-group"},[_c('h4',{staticClass:"m-0 mt-3"},[_vm._v(" Software Component ")]),_c('hr',{staticClass:"m-0 mb-3"}),_c('label',[_vm._v("Name (optional)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.softwareComponent.name),expression:"softwareComponent.name"}],ref:"name",staticClass:"form-control",domProps:{"value":(_vm.softwareComponent.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.softwareComponent, "name", $event.target.value)}}}),_c('hr',{staticClass:"m-0 mb-3"}),_c('label',[_vm._v(_vm._s(_vm.$t("type")))]),(_vm.softwareComponentTypes)?_c('Multiselect',{class:[
          { 'is-invalid': this.$validator.errors.has('ExternalResortId') } ],attrs:{"id":"types","options":_vm.softwareComponentTypes,"placeholder":"Select a Software Component Type","custom-label":_vm.customLabel,"close-on-select":true},model:{value:(_vm.softwareComponent.type),callback:function ($$v) {_vm.$set(_vm.softwareComponent, "type", $$v)},expression:"softwareComponent.type"}}):_vm._e(),_c('hr',{staticClass:"m-0 mb-3"}),_c('label',[_vm._v("Zabbix monitored URL")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.softwareComponent.url),expression:"softwareComponent.url"},{name:"focus",rawName:"v-focus"}],ref:"url",class:[
          'url form-control',
          { 'is-invalid': _vm.$validator.errors.has('Url') } ],attrs:{"required":"","pattern":"https://.*|http://.*","type":"url"},domProps:{"value":(_vm.softwareComponent.url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.softwareComponent, "url", $event.target.value)}}}),_c('hr',{staticClass:"m-0 mb-3"}),_c('label',[_vm._v(_vm._s("Monitoring Proxy"))]),_c('Multiselect',{class:[
          { 'is-invalid': !_vm.softwareComponent.monitoringProxy } ],attrs:{"id":"proxies","options":_vm.monitoringProxies,"required":true,"placeholder":"Select a Proxy Type","close-on-select":true},model:{value:(_vm.softwareComponent.monitoringProxy),callback:function ($$v) {_vm.$set(_vm.softwareComponent, "monitoringProxy", $$v)},expression:"softwareComponent.monitoringProxy"}}),_c('hr',{staticClass:"m-0 mb-3"}),_c('label',[_vm._v(_vm._s("Configuration"))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.softwareComponent.manualConfigurationJson),expression:"softwareComponent.manualConfigurationJson"}],ref:"confTextArea",staticClass:"form-control",attrs:{"type":"text","rows":"10"},domProps:{"value":(_vm.softwareComponent.manualConfigurationJson)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.softwareComponent, "manualConfigurationJson", $event.target.value)}}}),_vm._v(" "),_c('hr',{staticClass:"m-0 mb-3"}),_c('button',{staticClass:"btn btn-primary float-right",attrs:{"disabled":!_vm.isUrlValid ||
            !_vm.softwareComponent.url ||
            !_vm.softwareComponent.type ||
            !_vm.softwareComponent.monitoringProxy},on:{"click":function($event){$event.preventDefault();return _vm.addSoftwareComponent()}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"check"}}),_vm._v(_vm._s(_vm.$t("add"))+" ")],1),_c('div',{staticClass:"clearfix"})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }